import cs from "date-fns/locale/cs";
import Fraction from "fraction.js";
import { TFunction } from "i18next";
import React, { useEffect } from "react";
import { Alert, Badge, Button, Col, Dropdown, Form, Image, InputGroup, Row, Table } from "react-bootstrap";
import { registerLocale } from "react-datepicker";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { DatePickerCustom } from "../../comp/DatePicker";
import { ErrorWrap } from "../../comp/errorwrap";
import { MonthYearDropdown } from "../../comp/month-year-dropdown";
import {
  date_formatISO,
  date_getNextWorkday,
  date_isAtMostNextWorkday,
  date_isHoliday,
  date_isToday,
  date_isValidObject,
  date_plusNyears,
  getDateInNMonths,
} from "../../lib/date-utils";
import { monthsOfYear, platneRoky } from "../../lib/month-year-utils";
import { is_warehouse_defined, warehouses_names_ids_separe } from "../../lists/warehouses-defs";
import { Pin } from "./Pin";
import { PurchaseOrderProcessedItem, Texts } from "./PurchaseOrder";
import { checkSnAmount, checkSnBatch } from "./stockNotificationChecks";
import { StockNotificationFormValues, StockNotificationItemBatchFormValues } from "./StockNotifications";

registerLocale("cs", cs);

/**
 * Makes sum of all batches in kg within one stockin item
 */
function getSnItemWeightInKg(
  processedItem: PurchaseOrderProcessedItem,
  itemBatches: StockNotificationItemBatchFormValues[]
) {
  const salesUnitKg = new Fraction(processedItem.item.storageUnitOfMeasurementWeight); //famous "how much sales unit weights in kg"
  return itemBatches //its array of dictionaries - reduce: for every dict in this array makes fraction from batch.amount, if possible, returns add amount*sales_unit_kg otherwise fraction(0)
    .reduce(function (acc, batch) {
      try {
        const amountFraction = new Fraction(batch.amount);
        return acc.add(amountFraction.mul(salesUnitKg));
      } catch (ex) {
        return new Fraction(0);
      }
    }, new Fraction(0));
}

function sumAmountsInItem(batches: StockNotificationItemBatchFormValues[]) {
  return batches
    .map((x) => x.amount)
    .filter((x) => !isNaN(x))
    .reduce((partial_sum, a) => partial_sum + a, 0);
}

function remainingInRightUnit(remaining: number, npj_HmotnostMj: number, nop_KodMj: string, p_KodMjSkl: string) {
  const different_units = nop_KodMj.trim().toLowerCase() !== p_KodMjSkl.trim().toLowerCase();
  return different_units ? new Fraction(remaining).mul(new Fraction(npj_HmotnostMj)) : new Fraction(remaining);
}

/**
 * Compares sum on one item with remaining material on item
 */
function sumInItemIsOverRemaining(
  remaining: number,
  batches: StockNotificationItemBatchFormValues[],
  npj_HmotnostMj: number,
  nop_KodMj: string,
  p_KodMjSkl: string
) {
  const sum_amounts = sumAmountsInItem(batches);
  const right_remaining = remainingInRightUnit(remaining, npj_HmotnostMj, nop_KodMj, p_KodMjSkl);
  return new Fraction(sum_amounts) > new Fraction(right_remaining);
}

/**
 * Counts weight of one package in purchase unit = package_weight_kg_wh / storage unit weight in kg;
 */
function get_package_weight_purchase_unit(package_weight_kg_wh: number, pj_HmotnostMj: number) {
  const result = new Fraction(package_weight_kg_wh).div(new Fraction(pj_HmotnostMj));
  return result.toString();
}

/**
 * Explanation and warning logic regarding too early eta dates.
 */
function early_ETA_date(date: string) {
  const today = new Date();
  const eta = new Date(date);
  const nextMonday = today;
  nextMonday.setDate(nextMonday.getDate() + 3); // only valid if todayIsFriday
  const weekDay = eta.getDay();
  const isWeekend = weekDay === 0 || weekDay === 6;
  const isHoliday = date_isHoliday(eta);

  if (isWeekend) {
    return "ord-eta_weekend";
  } else if (isHoliday) {
    return "ord-eta_holiday";
  } else {
    return true;
  }
}

function getItemDefaultBatch(warehouseConfigurationType: number | undefined, t: TFunction): string | undefined {
  return warehouseConfigurationType === 3 ? t("ord-not_filled") : undefined;
}

function getItemExpiration(item: PurchaseOrderProcessedItem, etaDate: string, t: TFunction) {
  if (item.warehouseConfiguration.type === 3 || item.warehouseConfiguration.type === 2) {
    const expiryMonths = item.warehouseConfiguration.months || 0;
    const eta = new Date(etaDate);
    const expiration = eta.setMonth(eta.getMonth() + expiryMonths);
    return date_formatISO(new Date(expiration));
  } else if (item.warehouseConfiguration.type === 1) {
    return t("ord-filled_by_wh");
  } else {
    return t("unknown4");
  }
}

//get unlocked items - used for number unlocked - gives states 0 - locked, 1 - entering pin, 2 - unlocked, 3 - unlocked by exceptional case
function getUnlocking(orderItemId: number, unlockedItems: StockNotificationFormValues["unlockedItems"]) {
  return unlockedItems[orderItemId] || 0;
}

interface StockNotificationStep2Props {
  orderCode: string;
  items: PurchaseOrderProcessedItem[];
  texts: Dto.OrderText[];
  hidden?: boolean;
}

/**
 * Second step of stockin with inputs for ETA, amounts, batches, optionaly expiry.
 */
export function StockNotificationStep2({ orderCode, items, texts, hidden = false }: StockNotificationStep2Props) {
  const { t } = useTranslation();
  const { formState, getValues, watch, setValue, register, unregister } = useFormContext<StockNotificationFormValues>();

  const pickedItems2 = watch("pickedItems");
  const etaDate = watch("eta");
  const etaIsToday = date_isToday(etaDate);

  const filteredItems: PurchaseOrderProcessedItem[] = items.filter(
    (item: PurchaseOrderProcessedItem) => pickedItems2[item.itemId]
  );
  useEffect(() => {
    register("unlockedItems");

    return () => {
      unregister("unlockedItems");
    };
  }, [register, unregister]);

  return (
    <div style={{ display: hidden ? "none" : undefined }}>
      <ErrorWrap>
        <Row>
          <Col xs="auto">
            <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail" style={{ maxWidth: "600px" }}>
              <Form.Label column={true} sm="auto">
                <h4 className="mb-0">🕒 ETA</h4>({t("ord-estimated_delivery_time")})
              </Form.Label>
              <Col>
                <Controller<StockNotificationFormValues>
                  name="eta"
                  rules={{ required: true, validate: (v: string) => early_ETA_date(v) }}
                  render={({ field }) => (
                    <DatePickerCustom
                      onChange={(etaDate: Date) => {
                        field.onChange(etaDate);
                        for (const item of filteredItems) {
                          const batches = getValues(`items.${item.itemId}.batches`).length;
                          for (let i = 0; i < batches; i++) {
                            setValue(
                              `items.${item.itemId}.batches.${i}.expiration`,
                              getItemExpiration(item, date_formatISO(etaDate), t)
                            );
                          }
                        }
                      }}
                      minDate={new Date()}
                      maxDate={getDateInNMonths()}
                      value={field.value as string}
                    />
                  )}
                />
                <Form.Text className="text-muted">{etaDate === "" ? t("ord-fill_first") : ""}</Form.Text>
              </Col>
            </Form.Group>
            <h4 className="mb-0 mt-2">
              <Image src="/img/warehouse.svg" height="30" /> {t("warehouse")}{" "}
              {filteredItems[0] ? warehouses_names_ids_separe[filteredItems[0].item.warehouseBookCode] : ""}
            </h4>
          </Col>
          <Col>
            {!!formState.errors.eta || etaIsToday ? (
              <Alert variant="warning" style={{ maxWidth: "650px" }}>
                {t(formState.errors.eta?.message ?? "ord-eta_today")}
                <br />
                <Button
                  className="mt-2"
                  variant="light"
                  size="sm"
                  onClick={() => setValue("eta", date_getNextWorkday(getValues("eta")), { shouldValidate: true })}
                >
                  {t("ord-set_eta_next_workday")}
                </Button>
              </Alert>
            ) : (
              ""
            )}
          </Col>
          <Col xs="4">
            <Texts texts={texts} />
          </Col>
        </Row>
        <h5 className="mt-5">{t("ord-notifyied_items")}</h5>
        <Table borderless size="sm" className="mb-0 bg-white">
          <thead className="beGray">
            <tr>
              <th style={{ width: "3%" }}>{t("prod-id")}</th>
              <th style={{ width: "10%" }} className="text-center">
                {t("name")}
              </th>
              <th style={{ width: "5%" }} className="text-center">
                {t("ord-remaining")}
              </th>
              <th style={{ width: "5%" }} className="text-center">
                {t("ord-1_pack_size")}
              </th>
              <th style={{ width: "20%" }}>{t("ord-notified_amount")}</th>
              <th className="text-end">{t("measure_unit")}&nbsp;</th>
              <th style={{ width: "15%" }}>{t("ord-batch")}</th>
              <th style={{ width: "11%" }} className="text-center">
                {t("ord-expiry")}
              </th>
              <th style={{ width: "9%" }}></th>
              <th style={{ width: "7%" }}></th>
              <th style={{ width: "10%" }}>{t("ord-items_action")}</th>
            </tr>
          </thead>
          <tbody>
            {filteredItems.map((item, idx) => (
              <StockNotification2Item item={item} key={idx} />
            ))}
          </tbody>
        </Table>
        <span className="text-muted mb-0">
          <Badge pill bg="secondary">
            i
          </Badge>
          &nbsp;{t("ord-amounts_and_batches_are_mandatory")}.
        </span>

        <Row className="mt-5">
          <Col>
            <LinkContainer to={{ pathname: "/orders/purchase/" + orderCode + "/notification/1" }}>
              <Button>{t("ord-back_to_1st_step")}</Button>
            </LinkContainer>
          </Col>
          <Col className="text-end">
            <LinkContainer to={{ pathname: "/orders/purchase/" + orderCode + "/notification/3" }}>
              <Button disabled={!formState.isValid}>{t("ord-sn_summary")}</Button>
            </LinkContainer>
          </Col>
        </Row>
      </ErrorWrap>
    </div>
  );
}

interface StockNotification2ItemProps {
  item: PurchaseOrderProcessedItem;
}

function StockNotification2Item({ item }: StockNotification2ItemProps) {
  const { t } = useTranslation();
  const { control, register, formState, getFieldState, getValues, watch } =
    useFormContext<StockNotificationFormValues>();
  const etaTomorrowOrNextWorkday = date_isAtMostNextWorkday(watch("eta"));
  const batches = watch(`items.${item.itemId}.batches`) ?? [];
  const moreThan5t = getSnItemWeightInKg(item, batches) >= new Fraction(5000);

  const { fields, append, remove } = useFieldArray<StockNotificationFormValues>({
    name: `items.${item.itemId}.batches`,
    control,
  });
  const multipleBatches = fields.length > 1;
  const batchAmountSum =
    getValues(`items.${item.itemId}.batches`)?.reduce((acc, batch) => {
      const amount = batch.amount;
      return isNaN(amount) ? acc : acc + amount;
    }, 0) ?? 0;
  const sum_amount_over_remaining = sumInItemIsOverRemaining(
    item.item.remainingQuantity,
    getValues(`items.${item.itemId}.batches`) ?? [],
    item.item.storageUnitOfMeasurementWeight,
    item.item.storageUnitOfMeasurementCode,
    item.storageUnitOfMeasurementCode
  );
  const isUnlocked =
    getUnlocking(item.itemId, watch("unlockedItems")) === 2 || getUnlocking(item.itemId, watch("unlockedItems")) === 3;

  useEffect(() => {
    append({
      amount: undefined,
      batch: getItemDefaultBatch(item.warehouseConfiguration.type, t),
      expiryType: "default",
      expiration: null,
    });
    return () => {
      remove(0);
    };
  }, [append, remove, item.warehouseConfiguration.type, t]);

  return fields.map((batch, index) => {
    const is_last = index === fields.length - 1;
    const borderBottomLast = is_last ? " border-bottom " : "";
    const formsCentering = !multipleBatches ? " align-middle " : "";
    const eta = watch("eta");
    const etaValid = getFieldState("eta").isDirty && !getFieldState("eta").invalid;
    const amountState = getFieldState(`items.${item.itemId}.batches.${index}.amount`, formState);
    const batchState = getFieldState(`items.${item.itemId}.batches.${index}.batch`, formState);

    return (
      <tr key={`${item.itemId}-${index}`}>
        {index === 0 && (
          <>
            <td className=" border-bottom border-start align-middle" rowSpan={fields.length}>
              {item.productId}
            </td>
            <td className=" border-bottom align-middle" rowSpan={fields.length}>
              {item.item.productName}
            </td>
            <td className=" border-bottom align-middle text-center" rowSpan={fields.length}>
              {item.item.remainingQuantity}&nbsp;{item.item.unitOfMeasurementCode}
            </td>
            <td className=" border-bottom align-middle text-center" rowSpan={fields.length}>
              {get_package_weight_purchase_unit(item.package_weight_kg_wh, item.unitOfMeasurementWeight)}&nbsp;
              {item.storageUnitOfMeasurementCode}
            </td>
          </>
        )}
        <td colSpan={2} className={borderBottomLast + formsCentering}>
          <InputGroup hasValidation size="sm">
            <Form.Control
              type="text"
              {...register(`items.${item.itemId}.batches.${index}.amount`, {
                required: true,
                valueAsNumber: true,
                validate: (v) => checkSnAmount(v.toString(), item, t)[1],
              })}
              disabled={!etaValid}
              placeholder=""
              isInvalid={amountState.isDirty && !!amountState.error}
              isValid={amountState.isDirty && !amountState.error}
            />
            <InputGroup.Text>{item.item.unitOfMeasurementCode}</InputGroup.Text>
            <Form.Control.Feedback type="invalid">
              {formState.errors.items?.[item.itemId]?.batches[index]?.amount?.message}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
          </InputGroup>
          {is_last ? (
            <>
              {fields.length > 1 ? (
                <small className={sum_amount_over_remaining ? "text-danger fw-bold" : ""}>
                  {t("ord-amount_total")}: {batchAmountSum}.{" "}
                </small>
              ) : (
                ""
              )}
              {sum_amount_over_remaining ? (
                <small className="text-danger fw-bold">
                  {t("ord-remaining_is")} {item.item.remainingQuantity} {item.item.unitOfMeasurementCode?.trim() ?? ''}
                  {t("ord-lower_sn_amount")}
                </small>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </td>
        <td className={borderBottomLast + formsCentering}>
          <Form.Group controlId="sarze" key={index} className="mb-0">
            <Form.Control
              type="batch"
              size="sm"
              {...register(`items.${item.itemId}.batches.${index}.batch`, {
                required: true,
                minLength: 1,
                validate: (v) => checkSnBatch(v, item, undefined, undefined)[1],
              })}
              placeholder=""
              disabled={!etaValid || isUnlocked || item.warehouseConfiguration.type === 3}
              isInvalid={batchState.isDirty && !!batchState.error}
              isValid={batchState.isDirty && !batchState.error}
            />
            <Form.Control.Feedback type="invalid">{t(batchState?.error?.message)}</Form.Control.Feedback>
            <Form.Control.Feedback type="valid"></Form.Control.Feedback>
          </Form.Group>
        </td>
        <td className={borderBottomLast + formsCentering + "text-end"}>
          <ExpirySetting item={item} index={index} />
        </td>
        <td className={borderBottomLast + formsCentering}>
          <ExpiryDropdown item={item} batch={batch} index={index} disabledAdding={!etaValid} />
        </td>
        <td className={borderBottomLast + formsCentering}>
          {fields.length > 1 ? (
            <Button variant="light" size="sm" className="d-block" onClick={() => remove(index)}>
              {t("ord-delete_batch")}
            </Button>
          ) : (
            ""
          )}
        </td>
        {index === 0 && (
          <>
            <td rowSpan={fields.length} className="border-bottom">
              <Pin
                nop_IDPolozky={item.itemId}
                disabledForms={item.warehouseConfiguration.type === 3 || !etaValid || fields.length > 1}
                // exceptionCase={etaTomorrowOrNextWorkday || moreThan5t}
                exceptionCase={false} // exceptions to starting stockin are turned off
              />
              <Button
                variant="info"
                size="sm"
                className=" w-100 my-2 mb-1"
                disabled={false}
                onClick={() =>
                  append({
                    amount: undefined,
                    batch: getItemDefaultBatch(item.warehouseConfiguration.type, t),
                    expiryType: "default",
                    expiration: getItemExpiration(item, eta, t),
                  })
                }
              >
                {t("ord-add_batch")}
              </Button>
            </td>
          </>
        )}
      </tr>
    );
  });
}

interface ExpiryDropdownProps {
  disabledAdding: boolean;
  batch: StockNotificationItemBatchFormValues;
  item: PurchaseOrderProcessedItem;
  index: number;
}

function ExpiryDropdown({ disabledAdding, batch, item, index }: ExpiryDropdownProps) {
  const { t } = useTranslation();

  const allowExpiryEditing = is_warehouse_defined(item.item.warehouseBookCode);
  return allowExpiryEditing ? (
    item.warehouseConfiguration.type === 1 ? (
      <Controller
        name={`items.${item.itemId}.batches.${index}.expiryType`}
        render={({ field }) => (
          <Dropdown className="d-flex">
            <Dropdown.Toggle
              className=""
              size="sm"
              variant="light"
              id="dropdown-basic"
              disabled={disabledAdding || batch.batch === "neznámá"}
            >
              {t("ord-fill_expiry")}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  field.onChange("default");
                }}
              >
                {t("ord-default_settings")}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  field.onChange("exact-date");
                }}
              >
                {t("ord-exact_date")}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  field.onChange("month-year");
                }}
              >
                {t("ord-month_year")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      />
    ) : (
      <></>
    )
  ) : (
    ""
  );
}

interface ExpirySettingProps {
  item: PurchaseOrderProcessedItem;
  index: number;
}

function ExpirySetting({ item, index }: ExpirySettingProps) {
  const { t } = useTranslation();
  const { watch, setValue, getValues } = useFormContext<StockNotificationFormValues>();

  const minDate = date_plusNyears(new Date(), -2);
  const maxDate = date_plusNyears(new Date(), 11);
  const years = platneRoky(minDate, maxDate).reverse();
  const { expiryType, expiration } = watch(`items.${item.itemId}.batches.${index}`);
  const date_value = date_isValidObject(expiration) ? new Date(expiration) : new Date();
  const months = monthsOfYear(minDate, maxDate, date_value.getFullYear());

  useEffect(() => {
    if (expiryType !== "default")
      setValue(`items.${item.itemId}.batches.${index}.expiration`, date_formatISO(new Date()), {
        shouldValidate: true,
      });
    else
      setValue(
        `items.${item.itemId}.batches.${index}.expiration`,
        getItemExpiration(item, date_formatISO(getValues("eta")), t),
        {
          shouldValidate: true,
        }
      );
  }, [expiryType, getValues, setValue, item.itemId, index, t]);

  return (
    <Controller
      name={`items.${item.itemId}.batches.${index}.expiration`}
      render={({ field }) => (
        <div className="float-end">
          {expiryType === "default" ? (
            <Form.Group controlId="expirace" key={index} className="mb-0">
              <Form.Control size="sm" type="text" style={{ width: "197px" }} value={field.value} disabled />
            </Form.Group>
          ) : (
            ""
          )}
          {expiryType === "exact-date" ? (
            <DatePickerCustom
              onChange={(ev) => field.onChange(ev)}
              minDate={minDate}
              maxDate={maxDate}
              value={date_value}
              size="sm"
            />
          ) : (
            ""
          )}
          {expiryType === "month-year" ? (
            <MonthYearDropdown
              today={date_value}
              setToday={(ev) => field.onChange(ev)}
              years={years}
              months={months}
              minDate={minDate}
              maxDate={maxDate}
              withoutArrows
              variant="light"
              whiteStyle
            />
          ) : (
            ""
          )}
        </div>
      )}
    />
  );
}
